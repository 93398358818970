export enum WebzoneUniverseType {
  TOP_KIFF = "top_kif",
  DEFAULT = "default",
  TEAM_MODEL_DEFAULT = "team_model_default",
  FIDELITY_REWARD_DEFAULT = "fidelity_reward_default",
}

export enum OrderDirection {
  ASC = "asc",
  DESC = "desc",
}
