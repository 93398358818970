import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import {
  OrderDirection,
  WebzoneUniverseType,
} from "./Utils/Webzone/Webzone.enum";

const validateRequired = [required()];
const webzoneUniverseTypes = [
  { id: WebzoneUniverseType.TOP_KIFF, name: "Top Kif" },
  { id: WebzoneUniverseType.DEFAULT, name: "Home Page" },
  {
    id: WebzoneUniverseType.TEAM_MODEL_DEFAULT,
    name: "Team Model",
  },
  {
    id: WebzoneUniverseType.FIDELITY_REWARD_DEFAULT,
    name: "Bonus Fidélité",
  },
];
const webzoneUniverseFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput label="Type" source="type" choices={webzoneUniverseTypes} />,
];

export const WebzoneUniverseList = (props: any) => (
  <List {...props} bulkActionButtons={false} filters={webzoneUniverseFilters}>
    <Datagrid rowClick="edit">
      {/* <TextField source="id" /> */}
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="type" />
      <NumberField source="order" />
      <BooleanField source="isEnabled" />
      <BulkDeleteButton />
    </Datagrid>
  </List>
);

export const WebzoneUniverseCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="slug" />
      <SelectInput
        label="Type"
        source="type"
        choices={webzoneUniverseTypes}
        validate={validateRequired}
        initialValue={WebzoneUniverseType.DEFAULT}
      />

      <NumberInput source="order" validate={validateRequired} />
      <SelectInput
        label="Direction du contenu"
        source="orderDirection"
        choices={[
          { id: OrderDirection.ASC, name: "ASC" },
          { id: OrderDirection.DESC, name: "DESC" },
        ]}
        validate={validateRequired}
      />

      <BooleanInput
        source="isEnabled"
        validate={validateRequired}
        initialValue={true}
      />
    </SimpleForm>
  </Create>
);

export const WebzoneUniverseEdit = (props: any) => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="slug" />
      <SelectInput
        label="Type"
        source="type"
        choices={webzoneUniverseTypes}
        validate={validateRequired}
      />

      <NumberInput source="order" validate={validateRequired} />
      <SelectInput
        label="Direction du contenu"
        source="orderDirection"
        choices={[
          { id: OrderDirection.ASC, name: "ASC" },
          { id: OrderDirection.DESC, name: "DESC" },
        ]}
        validate={validateRequired}
      />

      <BooleanInput source="isEnabled" validate={validateRequired} />
    </SimpleForm>
  </Edit>
);
