import { fetchUtils } from 'react-admin';
import Config from "./Config";

const apiUrl = Config.API_URL;
const httpClient = fetchUtils.fetchJson;
const authProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }: any) => {
        const url = `${apiUrl}/auth/login`;
        const body = {
            email: username,
            password: password
        }
        const response = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(body),
        }).then(({ headers, json }) => ({
            data: json}));
        localStorage.setItem('access_token', response.data.access_token);
        return Promise.resolve();
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('access_token');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }: any): Promise<void> => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('access_token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('access_token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};
export default authProvider;