import RichTextInput from "ra-input-rich-text";
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  CreateProps,
  Create,
  TextInput,
  required,
  EditProps,
  Edit,
  SelectInput,
  SimpleForm,
  BulkDeleteButton,
  DateField,
  FormTab,
  TabbedForm,
  ImageInput,
  ImageField,
  useEditController,
} from "react-admin";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useStyles } from "./PageListStyle";
import { ImagePosition, PublicationStatus } from "./Utils/News/News.enum";
import ConditionalImageField from "./Utils/Media/ConditionalImageField";
import { rowStyles } from "./Utils/Media/MediaListStyle";
import ClearFieldButton from "./Utils/Buttons/ClearFieldButton";

const validateRequired = [required()];
const statusChoice = [
  { id: PublicationStatus.DRAFT, name: "Brouillon" },
  { id: PublicationStatus.PUBLISHED, name: "Publié" },
];
const NewsFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    label="Status"
    source="publicationStatus"
    choices={statusChoice}
  />,
  // <SelectInput label="Type" source="type" choices={pageTypes} />,
];
export const NewsList = (props: ListProps) => {
  const styleClasses = useStyles();
  return (
    <List {...props} bulkActionButtons={false} filters={NewsFilters}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="content" className={styleClasses.content} />
        <TextField label="Status" source="publicationStatus" />
        <DateField showTime={true} source="created_at" />
        <DateField showTime={true} source="updated_at" />

        <BulkDeleteButton />
      </Datagrid>
    </List>
  );
};

export const NewsCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Titre" source="title" validate={validateRequired} />
        <RichTextInput
          label="description"
          source="content"
          toolbar={[
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "link"],

            // [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
          ]}
          validate={validateRequired}
        />
        <SelectInput
          label="Status"
          source="publicationStatus"
          choices={[
            { id: PublicationStatus.DRAFT, name: "Brouillon" },
            { id: PublicationStatus.PUBLISHED, name: "Publié" },
          ]}
          initialValue={PublicationStatus.DRAFT}
          validate={validateRequired}
        />
      </SimpleForm>
    </Create>
  );
};

export const NewsEdit = (props: EditProps) => {
  const classes = rowStyles();
  const controllerProps = useEditController(props);
  const record = controllerProps.record;
  const content = record ? record.content : "";
  const isMobile = useMediaQuery("(max-width:600px)");

  let containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  };
  let imagePositionStyle: React.CSSProperties = { order: 0 };

  switch (record?.imagePosition) {
    case ImagePosition.LEFT:
      containerStyle.flexDirection = "row";
      imagePositionStyle.order = 0;
      break;
    case ImagePosition.RIGHT:
      containerStyle.flexDirection = "row";
      imagePositionStyle.order = 1;
      break;
    case ImagePosition.TOP:
      containerStyle.flexDirection = "column";
      imagePositionStyle.order = 0;
      break;
    case ImagePosition.BOTTOM:
      containerStyle.flexDirection = "column-reverse";
      imagePositionStyle.order = 0;
      break;
    default:
      break;
  }

  return (
    <Edit mutationMode="pessimistic" {...props}>
      <TabbedForm>
        <FormTab label="Summary">
          <TextInput label="Titre" source="title" validate={validateRequired} />
          <RichTextInput
            label="description"
            source="content"
            toolbar={[
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "link"],

              // [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"],
            ]}
            validate={validateRequired}
          />
          <SelectInput
            label="Status"
            source="publicationStatus"
            choices={[
              { id: PublicationStatus.DRAFT, name: "Brouillon" },
              { id: PublicationStatus.PUBLISHED, name: "Publié" },
            ]}
            validate={validateRequired}
          />
        </FormTab>
        <FormTab label="Image">
          <Grid container spacing={2} className={classes.widthAuto}>
            <Grid item xs={6}>
              <Paper elevation={10}>
                <Typography variant="h5">Image.</Typography>
              </Paper>
              <Grid container spacing={2} className={classes.widthAuto}>
                <Grid item xs={6}>
                  <ConditionalImageField
                    source="picture"
                    title="current picture"
                  />
                </Grid>
                <Grid item xs={6}>
                  <ImageInput
                    source="picture_update"
                    label="Update Image"
                    accept="image/*"
                    className={classes.displayFlexColumn}
                  >
                    <ImageField source="picture" title="current banner" />
                  </ImageInput>
                  <ClearFieldButton
                    source="picture"
                    target="picture_update"
                    label="Clear"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Position"
                source="imagePosition"
                choices={[
                  { id: ImagePosition.LEFT, name: "Gauche" },
                  { id: ImagePosition.RIGHT, name: "Droite" },
                  { id: ImagePosition.TOP, name: "Haut" },
                  { id: ImagePosition.BOTTOM, name: "Bas" },
                  { id: ImagePosition.NONE, name: "Cachée" },
                ]}
                validate={validateRequired}
              />
            </Grid>
          </Grid>
          <Card
            style={{
              backgroundColor: "#7c685a",
              width: "100%",
              maxWidth: isMobile ? "none" : "50vw",
            }}
          >
            <CardHeader title="Preview" style={{ border: "1px solid grey" }} />
            <CardContent style={{ border: "1px solid grey" }}>
              <div style={containerStyle}>
                <div style={imagePositionStyle}>
                  <ConditionalImageField
                    source="picture"
                    title="current picture"
                  />
                </div>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </CardContent>
          </Card>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
