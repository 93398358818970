import { useCallback, useState } from "react";
import { Button, ButtonProps, useRecordContext } from "react-admin";
import { UserStatus } from "../User.enum";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import GetDateModal from "./getdateModal";
import dataProvider from "../../dataProvider";

type myProps = ButtonProps;

const UnsubscribeButton = ({ ...props }: myProps) => {
  const record = useRecordContext(props);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen(false);
  }, []);
  const handleConfirm = useCallback(
    async (event, date: Date) => {
      console.log(
        "IN Unsubscrive button handleConfirm",
        record.email,
        record.id,
        date
      );
      await dataProvider.unsubscribeUser({ id: record.id, date: date });
      setOpen(false);
    },
    [record.email, record.id]
  );

  return record && record.status === UserStatus.MEMBER_PREMIUM ? (
    <>
      <Button
        onClick={handleClick}
        {...props}
        // className="User"
        style={{ color: "red" }}
        // label="Unsubscribe"
        // startIcon={WebIcon}
      >
        <RemoveShoppingCartIcon />
      </Button>
      <GetDateModal
        show={open}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
      ></GetDateModal>
      {/* <Confirm
        isOpen={open}
        // loading={isLoading}
        title="Forcer l'annulation de l'abonnement"
        content={`Etes vous sure de vouloir forcer le désabonnement de ${record.email}`}
        // content={<GetDateModal show={true}></GetDateModal>}
        onConfirm={handleDialogConfirm}
        onClose={handleDialogClose}
      /> */}
    </>
  ) : (
    <></>
  );
};

export default UnsubscribeButton;
