import Config from "../../Config";
import { fetchUtils } from "react-admin";

const apiUrl = Config.API_URL;
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const access_token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};
const UniverseDataProvider = {
  // getSeason: async (mediaList: any[]) => {
  //   for (const element of mediaList) {
  //     for (const webzone of element.media.webzones) {
  //       if (webzone.type === WebzoneType.SEASON) {
  //         element.seasonName = webzone.name;
  //       }
  //     }
  //   }
  //   return mediaList;
  // },
  getSeason: async (universeList: any[]) => {
    for (const element of universeList) {
      for (const webzone of element.webzoneUniverses) {
        // if (webzone.type === WebzoneType.DEFAULT) {
        element.seasonName = webzone.name;
        // }
      }
    }
    return universeList;
  },
  getUniversesBanner: async (universeList: any[]) => {
    for (const element of universeList) {
      try {
        let url = apiUrl + "/universe/" + element.id + "/banner_vignette/url";
        let { body } = await httpClient(url);
        element.banner = body;
        // url = apiUrl + '/media/' + element.media.id + '/mobile_banner/url';
        // ({ body } = await httpClient(url));
        // element.mobile_banner = body;
        // url = apiUrl + '/media/' + element.media.id + '/topkif/url';
        // ({ body } = await httpClient(url));
        // element.topkif = body;
        // url = apiUrl + '/media/' + element.media.id + '/home_banner/url';
        // ({ body } = await httpClient(url));
        // element.home_banner = body;
      } catch (error) {
        console.log("ERROR", error);
        element.banner = null;
      }
    }
    return universeList;
  },

  getUniverseContent: async (
    resource: string,
    json: {
      id: string;
      tagIds: string[];
      webzoneIds: string[];
      teaserId: string;
      teaserXId: string;
      episodeId: string;
      galleryId: string;
      banner: string | null;
      mobile_banner: string | null;
      topkif: string | null;
      home_banner: string | null;
      pictures_input: { url: any; desc: any }[];
      video_url: string | null;
      srt_url: string | null;
      teaser_video: string | null;
      teaser_srt: string | null;
    }
  ) => {
    try {
      // // GET BANNERS
      let url = apiUrl + "/universe/" + json.id + "/banner_vignette/url";
      let { body } = await httpClient(url);
      json.banner = body === "default" ? null : body;
      url = apiUrl + "/universe/" + json.id + "/mobile_banner_vignette/url";
      ({ body } = await httpClient(url));
      json.mobile_banner = body === "default" ? null : body;
      url = apiUrl + "/universe/" + json.id + "/topkif_vignette/url";
      ({ body } = await httpClient(url));
      json.topkif = body === "default" ? null : body;
      url = apiUrl + "/universe/" + json.id + "/home_banner_vignette/url";
      ({ body } = await httpClient(url));
      json.home_banner = body === "default" ? null : body;

      url = apiUrl + "/universe/" + json.id + "/TeaserVideo";
      ({ body } = await httpClient(url));
      json.teaser_video = body === "default" ? null : body;
      url = apiUrl + "/universe/" + json.id + "/TeaserSrt";
      ({ body } = await httpClient(url));
      json.teaser_srt = body === "" ? null : body;

      // GET TAGS and WEBZONES FROM MEDIA
      url = apiUrl + "/universe/" + json.id;

      const theUniverse = await httpClient(url);
      json.teaserId = theUniverse.json.teaser
        ? theUniverse.json.teaser.id
        : null;
      json.teaserXId = theUniverse.json.teaserX
        ? theUniverse.json.teaserX.id
        : null;
      json.episodeId = theUniverse.json.episode
        ? theUniverse.json.episode.id
        : null;
      json.galleryId = theUniverse.json.gallery
        ? theUniverse.json.gallery.id
        : null;
      // json.media.tags = theMedia.json.tags;
      json.tagIds = theUniverse.json.tags.map((x: any) => x.id);
      // json.media.webzones = theMedia.json.webzones;
      json.webzoneIds = theUniverse.json.webzoneUniverses.map((x: any) => x.id);
    } catch (error) {
      console.log("ERROR", error);
      json.banner = null;
      json.mobile_banner = null;
      json.topkif = null;
      json.home_banner = null;
      json.teaser_video = null;
      json.teaser_srt = null;
    }
    return json;
  },

  updateUniverse: async (
    resource: string,
    params: {
      data: {
        id: string;
        tagIds: string[];
        webzoneIds: string[];
        teaser: string;
        teaserId: string;
        teaserX: string;
        teaserXId: string;
        episode: string;
        episodeId: string;
        gallery: string;
        galleryId: string;
        // video_input: { rawFile: string | Blob };
        // srt_input: { rawFile: string | Blob };
        // pictures: any[];
        // pictures_input: any[];
        banner_update: { rawFile: string | Blob };
        mobile_banner_update: { rawFile: string | Blob };
        topkif_update: { rawFile: string | Blob };
        home_banner_update: { rawFile: string | Blob };
        teaser_video_update: { rawFile: string | Blob };
        teaser_srt_update: { rawFile: string | Blob };
      };
    }
    // previousData: {
    //   media: { accessRestriction: AccessRestriction };
    // };
  ) => {
    // UPDATE MEDIA
    // console.log("updateUniverse", params);
    params.data.teaser = params.data.teaserId;
    params.data.teaserX = params.data.teaserXId;
    params.data.episode = params.data.episodeId;
    params.data.gallery = params.data.galleryId;
    const url = `${apiUrl}/universe/${params.data.id}`;
    await httpClient(url, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    });
    // UPDATE TAGS
    if (params.data.tagIds) {
      const url = `${apiUrl}/universe/${params.data.id}/setTags`;
      await httpClient(url, {
        method: "PATCH",
        body: JSON.stringify({
          tag_ids: params.data.tagIds,
        }),
      });
    }

    //UPDATE WEBZONES
    if (params.data.webzoneIds) {
      const url = `${apiUrl}/universe/${params.data.id}/setZones`;
      await httpClient(url, {
        method: "PATCH",
        body: JSON.stringify({
          zone_ids: params.data.webzoneIds,
        }),
      });
    }
    // UPDATE BANNERS
    if (params.data.banner_update === null) {
      const url = `${apiUrl}/Universe/${params.data.id}/remove/banner`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.banner_update) {
      const url = `${apiUrl}/Universe/${params.data.id}/upload/banner`;
      const formData = new FormData();
      formData.append("file", params.data.banner_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.mobile_banner_update === null) {
      const url = `${apiUrl}/Universe/${params.data.id}/remove/mobile_banner`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.mobile_banner_update) {
      const url = `${apiUrl}/Universe/${params.data.id}/upload/mobile_banner`;
      const formData = new FormData();
      formData.append("file", params.data.mobile_banner_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.topkif_update === null) {
      const url = `${apiUrl}/Universe/${params.data.id}/remove/topkif`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.topkif_update) {
      const url = `${apiUrl}/Universe/${params.data.id}/upload/topkif`;
      const formData = new FormData();
      formData.append("file", params.data.topkif_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.home_banner_update === null) {
      const url = `${apiUrl}/Universe/${params.data.id}/remove/home_banner`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.home_banner_update) {
      const url = `${apiUrl}/Universe/${params.data.id}/upload/home_banner`;
      const formData = new FormData();
      formData.append("file", params.data.home_banner_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }

    if (params.data.teaser_video_update === null) {
      const url = `${apiUrl}/Universe/${params.data.id}/teaserVideo`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.teaser_video_update) {
      const url = `${apiUrl}/Universe/${params.data.id}/TeaserVideo`;
      const formData = new FormData();
      formData.append("file", params.data.teaser_video_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.teaser_srt_update === null) {
      const url = `${apiUrl}/Universe/${params.data.id}/teaserSrt`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.teaser_srt_update) {
      const url = `${apiUrl}/Universe/${params.data.id}/TeaserSrt`;
      const formData = new FormData();
      formData.append("file", params.data.teaser_srt_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
  },

  createUniverse: async (
    resource: string,
    params: {
      data: {
        title: string;
        description: string;
        order: number;
        slug: string;
        titleSeo: string;
        descriptionSeo: string;
        tags: string[];
        webzoneUniverses: string[];
        banner: { rawFile: string | Blob };
        mobile_banner: { rawFile: string | Blob };
        topkif: { rawFile: string | Blob };
        home_banner: { rawFile: string | Blob };
        teaser_video: { rawFile: string | Blob };
        teaser_srt: { rawFile: string | Blob };
      };
    }
  ): Promise<any> => {
    // console.log("params.data", params.data)
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    });

    // if (params.data.banner) {
    //   const url = `${apiUrl}/universe/${json.id}/upload/banner`;
    //   const formData = new FormData();
    //   formData.append("file", params.data.banner.rawFile);

    //   await httpClient(url, {
    //     method: "POST",
    //     body: formData,
    //   });
    // }
    // if (params.data.mobile_banner) {
    //   const url = `${apiUrl}/universe/${json.id}/upload/mobile_banner`;
    //   const formData = new FormData();
    //   formData.append("file", params.data.mobile_banner.rawFile);

    //   await httpClient(url, {
    //     method: "POST",
    //     body: formData,
    //   });
    // }
    // if (params.data.topkif) {
    //   const url = `${apiUrl}/universe/${json.id}/upload/topkif`;
    //   const formData = new FormData();
    //   formData.append("file", params.data.topkif.rawFile);

    //   await httpClient(url, {
    //     method: "POST",
    //     body: formData,
    //   });
    // }
    // if (params.data.home_banner) {
    //   const url = `${apiUrl}/universe/${json.id}/upload/home_banner`;
    //   const formData = new FormData();
    //   formData.append("file", params.data.home_banner.rawFile);

    //   await httpClient(url, {
    //     method: "POST",
    //     body: formData,
    //   });

    //   // if (params.data.teaser_video) {
    //   //   const url = `${apiUrl}/Media/${json.id}/TeaserVideo`;
    //   //   const formData = new FormData();
    //   //   formData.append("file", params.data.teaser_video.rawFile);

    //   //   await httpClient(url, {
    //   //     method: "POST",
    //   //     body: formData,
    //   //   });
    //   // }
    //   if (params.data.teaser_srt) {
    //     const url = `${apiUrl}/Universe/${json.id}/TeaserSrt`;
    //     const formData = new FormData();
    //     formData.append("file", params.data.teaser_srt.rawFile);

    //     await httpClient(url, {
    //       method: "POST",
    //       body: formData,
    //     });
    //   }
    // }
    // UPDATE TAGS
    if (params.data.tags) {
      const url = `${apiUrl}/universe/${json.id}/setTags`;
      await httpClient(url, {
        method: "PATCH",
        body: JSON.stringify({
          tag_ids: params.data.tags,
        }),
      });
    }

    //UPDATE WEBZONES
    if (params.data.webzoneUniverses) {
      const url = `${apiUrl}/universe/${json.id}/setZones`;
      await httpClient(url, {
        method: "PATCH",
        body: JSON.stringify({
          zone_ids: params.data.webzoneUniverses,
        }),
      });
    }
    return json;
  },
};

export default UniverseDataProvider;
