import {
  BulkDeleteButton,
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditProps,
  List,
  ListProps,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

const tagFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  // <SelectInput
  //   label="accessRestriction"
  //   source="media.accessRestriction"
  //   choices={mediaAccessRestrictions}
  // />,
];
export const TagList = (props: ListProps) => (
  <List {...props} bulkActionButtons={false} filters={tagFilters}>
    <Datagrid rowClick="edit">
      {/* <TextField source="id" /> */}
      <TextField source="label" />
      <TextField source="slug" />
      <BulkDeleteButton />
    </Datagrid>
  </List>
);

export const TagCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="label" />
    </SimpleForm>
  </Create>
);

export const TagEdit = (props: EditProps) => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput source="label" />
    </SimpleForm>
  </Edit>
);
