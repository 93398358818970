import MediaCreate from './MediaCreate';
import MediaEdit from './MediaEdit';
import MediaList from './MediaList';

const Medias = {
    MediaList: MediaList,
    MediaCreate: MediaCreate,
    MediaEdit: MediaEdit,
    // MediaShow: MediaShow,
    // BookIcon: BookIcon,
}

export  default Medias;
