import * as React from "react";
import { Record } from "react-admin";

interface MediaTitleProps {
  record?: Record; // make this an optional property
}
const MediaTitle = ({ record }: MediaTitleProps) => {
  return (
    <span>
      {record && record.media ? record.media.type : "Créer"}{" "}
      {record ? (record.media ? `"${record.media.title}"` : record.title) : ""}
    </span>
  );
};

export default MediaTitle;
