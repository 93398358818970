import Config from "../../Config";
import { fetchUtils } from "react-admin";
// import { WebzoneType } from "../Webzone/Webzone.enum";

const apiUrl = Config.API_URL;
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const access_token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};
const NewsDataProvider = {
  getListNewsPicture: async (newsList: any[]) => {
    for (const element of newsList) {
      try {
        let url = apiUrl + "/universe/" + element.id + "/picture_vignette/url";
        let { body } = await httpClient(url);
        element.banner = body;
        // url = apiUrl + '/media/' + element.media.id + '/mobile_banner/url';
        // ({ body } = await httpClient(url));
        // element.mobile_banner = body;
        // url = apiUrl + '/media/' + element.media.id + '/topkif/url';
        // ({ body } = await httpClient(url));
        // element.topkif = body;
        // url = apiUrl + '/media/' + element.media.id + '/home_banner/url';
        // ({ body } = await httpClient(url));
        // element.home_banner = body;
      } catch (error) {
        console.log("ERROR", error);
        element.banner = null;
      }
    }
    return newsList;
  },

  getNewsContent: async (
    resource: string,
    json: {
      id: string;
      picture: string | null;
      vignette: string | null;
    }
  ) => {
    try {
      // // GET BANNERS
      let url = apiUrl + "/news/" + json.id + "/picture/url";
      let { body } = await httpClient(url);
      json.picture = body === "default" ? null : body;
      console.log(json.picture);
      // url = apiUrl + "/news/" + json.id + "/vignette/url";
      // ({ body } = await httpClient(url));
      // json.vignette = body === "default" ? null : body;
    } catch (error) {
      console.log("ERROR", error);
      json.picture = null;
      json.vignette = null;
    }
    return json;
  },

  updateNews: async (
    resource: string,
    params: {
      data: {
        id: string;
        picture_update: { rawFile: string | Blob };
      };
    }
    // previousData: {
    //   media: { accessRestriction: AccessRestriction };
    // };
  ) => {
    // UPDATE MEDIA
    // console.log("News", params);
    const url = `${apiUrl}/news/${params.data.id}`;
    await httpClient(url, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    });

    // UPDATE IMAGE
    if (params.data.picture_update === null) {
      const url = `${apiUrl}/news/${params.data.id}/removeimage`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.picture_update) {
      const url = `${apiUrl}/news/${params.data.id}/uploadimage`;
      const formData = new FormData();
      formData.append("file", params.data.picture_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
  },
};

export default NewsDataProvider;
