import {
    CreateProps,
    EditProps,
    ListProps,
} from 'react-admin';
import Media from "./Utils/Media/Media";
const { MediaCreate, MediaEdit, MediaList } = Media;

export const VideoList = (props: ListProps) => (
    <MediaList {...props} />
);

export const VideoEdit = (props: EditProps) => (
    <MediaEdit {...props} />
);

export const VideoCreate = (props: CreateProps) => (
    <MediaCreate {...props} />
);