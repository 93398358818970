const test = process.env.REACT_APP_MODE !== "production";

const removeLogs = () => {
  console.log = function () {};
};
//TODO interface ?
const prod = {
  removeLogs,
  debug: test,
  API_URL: "https://api.littleangel84.fr",
  CF_DOMAIN: ".littleangel84.fr",
};

const devt = {
  debug: test,
  API_URL: "https://api-preprod.littleangel84.fr",
  CF_DOMAIN: ".littleangel84.fr",
};

const local = {
  debug: test,
  API_URL: "http://localhost:3000",
  CF_DOMAIN: ".littleangel84.fr",
};

const Config =
  process.env.REACT_APP_MODE === "local"
    ? local
    : process.env.REACT_APP_MODE === "development"
    ? devt
    : prod;
// const config = (process.env.NODE_ENV === 'development') ? devt : ((process.env.NODE_ENV === 'local') ? local : prod);

export default Config;
