import { EditProps, ListProps, CreateProps } from "react-admin";
import Universe from "./Utils/Media/Universe";
const { UniverseCreateUtil, UniverseEditUtil, UniverseListUtil } = Universe;

export const UniverseList = (props: ListProps) => (
  <UniverseListUtil {...props} />
);

export const UniverseEdit = (props: EditProps) => (
  <UniverseEditUtil {...props} />
);

export const UniverseCreate = (props: CreateProps) => (
  <UniverseCreateUtil {...props} />
);
