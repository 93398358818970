import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  ListProps,
  NumberField,
  BulkDeleteButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  FunctionField,
  NumberFieldProps,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { mediaImageFieldStyles, useStyles } from "../MediaListStyle";
import ConditionalImageField from "../ConditionalImageField";
import { UniverseClass } from "../Universe.enum";

const descriptionPanel = ({ id, record, resource }: any) => (
  <div dangerouslySetInnerHTML={{ __html: record.description }} />
);
const universeClassifications = [
  { id: UniverseClass.EPISODE, name: "Episode" },
  { id: UniverseClass.TEAM_MODEL, name: "Team Model" },
  { id: UniverseClass.FIDELITY_REWARD, name: "Vidéo privée" },
];
const universeFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="WebzoneUniverse" reference="WebzoneUniverse">
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <SelectInput
    label="Classification"
    source="class"
    choices={universeClassifications}
  />,
];

interface ConditionalNumberFieldProps extends NumberFieldProps {
  source: string;
}
const ConditionalFidelityScoreField = (props: ConditionalNumberFieldProps) => {
  return (
    <FunctionField
      {...props}
      render={(record: any) =>
        record && record.class === UniverseClass.FIDELITY_REWARD ? (
          <NumberField {...props} />
        ) : null
      }
    />
  );
};

const UniverseList = (props: ListProps) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  // const classes = useStyles();
  const imageFieldClasses = mediaImageFieldStyles();
  const titleFieldClass = useStyles();
  return (
    // <List {...props} filters={MediaFilters} >
    <List {...props} bulkActionButtons={false} filters={universeFilters}>
      {isSmall ? (
        // <SimpleList className={classes.table}
        <SimpleList
          primaryText={(record) => record.title}
          secondaryText={(record) => record.order}
          // secondaryText={(record) =>
          //   `Nb images ${record.pictures ? record.pictures.length : 0}`
          // }
          // tertiaryText={(record) =>
          //   `${
          //     record.media
          //       ? record.media.accessRestriction
          //       : record.accessRestriction
          //   }`
          // }

          leftAvatar={(record) => (record.banner ? record.banner : null)}
        />
      ) : (
        // <Datagrid rowClick="edit" classes={classes} expand={descriptionPanel} >
        <Datagrid rowClick="edit" expand={descriptionPanel}>
          <ConditionalImageField
            source="banner"
            label="Banner"
            classes={imageFieldClasses}
          />
          {/* <TextField source="id" /> */}
          <TextField
            label="Titre"
            source="title"
            className={titleFieldClass.title}
          />{" "}
          <TextField label="Status" source="publicationStatus" />
          <TextField label="class" source="class" />
          <TextField label="Webzone" source="seasonName" sortable={false} />
          <NumberField label="N° épisode" source="order" />
          <ConditionalFidelityScoreField
            label="Points Fidélité"
            source="fidelityScore"
            sortable
          />{" "}
          <TextField label="slug" source="slug" />
          <NumberField label="Note" source="grade" />
          <NumberField label="Vues" source="views" />
          <DateField showTime={true} source="created_at" />
          <DateField showTime={true} source="updated_at" />
          {props.resource === "Gallery" && (
            <TextField label="Nb images" source="pictures.length" />
          )}
          <BulkDeleteButton />
          {/* <EditButton /> */}
        </Datagrid>
      )}
    </List>
  );
};

export default UniverseList;
