import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  content: {
    display: "block",
    width: "280px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },
});

export { useStyles };
