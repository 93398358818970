import * as React from "react";
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    DateField,
    Edit,
    Create,
    TextInput,
    EditProps,
    ListProps,
    CreateProps,
    BulkDeleteButton,
    TopToolbar,
    ListButton, SimpleForm} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { rowStyles } from "./Utils/Media/MediaListStyle";
    
export const SubscriptionList = (props: ListProps) => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  
    return (
    <List {...props} bulkActionButtons={false}>

        {isSmall ? (
            <SimpleList 
                primaryText={record => `Abonnement - ${record.title}`}
                tertiaryText={record => `${record.period} mois`}
            />
        ) : (
        <Datagrid rowClick="edit">
            <TextField label="Titre" source="title" />
            <TextField label="Durée" source="period" />
            <TextField label="Prix" source="price" />
            <DateField showTime={true} source="createdAt" />
            <DateField showTime={true} source="updatedAt" />
            <BulkDeleteButton />
        </Datagrid>
        )}

    </List>
    );
}


const SubscriptionEditActions = ({ basePath, record, resource }: any) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        {/* <RefreshButton color="primary" onClick={customAction} /> */}
    </TopToolbar>
);
export const SubscriptionEdit = (props: EditProps) => {
    rowStyles();
    return (
    <Edit title= "OuiOui"  mutationMode="optimistic" actions={<SubscriptionEditActions />} {...props} >
        <SimpleForm>
            <TextInput label="Titre" source="title" />
            <TextInput label="Slug" source="slug" />
            <TextInput  label="Durée" source="period" />
            <TextInput  label="Prix" source="price" />
        </SimpleForm>
    </Edit>
    );
};

export const SubscriptionCreate =(props: CreateProps) => {
    // console.log("PROPS", props);
    return (
    <Create title="eee" {...props}>
        <SimpleForm>
            <TextInput label="Titre" source="title" />
            <TextInput label="Slug" source="slug" />
            <TextInput label="Durée" source="period" />
            <TextInput label="Prix" source="price" />
        </SimpleForm>
    </Create>
    )};