import {
  EditProps,
  ListProps,
  CreateProps,
  List,
  Datagrid,
  TextField,
  BulkDeleteButton,
  ReferenceField,
  DateField,
  Edit,
  TopToolbar,
  ListButton,
  SelectInput,
  required,
  SimpleForm,
} from "react-admin";
import Universe from "./Utils/Media/Universe";
const { UniverseCreateUtil } = Universe;

const enum PublicationStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
}
const enum TeaserClass {
  UNIVERSE = "universe",
  TEAM_MODEL = "team-model",
  FIDELITY_REWARD = "fidelity-reward",
}
const validateRequired = [required()];

const TeaserEditActions = ({ basePath, data, resource }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    {/* <ShowButton basePath={basePath} record={data} /> */}
    {/* <RefreshButton color="primary" onClick={customAction} /> */}
  </TopToolbar>
);

// export const TeaserList = (props: ListProps) => <MediaList {...props} />;
export const TeaserList = (props: ListProps) => {
  // const styleClasses = useStyles();
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <ReferenceField
          label="Universe"
          source="universe.id"
          reference="Universe"
          link="edit"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="encodingWorkflow" />
        {/* <TextField source="content" className={styleClasses.content}/> */}
        <TextField source="width" />
        <TextField source="height" />
        <TextField label="Status" source="publicationStatus" />
        <TextField label="class" source="class" />
        <DateField showTime={true} source="created_at" />
        <DateField showTime={true} source="updated_at" />
        <BulkDeleteButton />
      </Datagrid>
    </List>
  );
};
// export const TeaserEdit = (props: EditProps) => <UniverseEditUtil {...props} />;
export const TeaserEdit = (props: EditProps) => {
  return (
    <Edit
      title="Teaser"
      mutationMode="optimistic"
      actions={<TeaserEditActions />}
      // undoable={false}
      {...props}
    >
      <SimpleForm>
        <ReferenceField
          label="Universe"
          source="universe.id"
          reference="Universe"
          link="edit"
        >
          <TextField source="title" />
        </ReferenceField>
        <TextField source="encodingWorkflow" />
        <TextField source="width" />
        <TextField source="height" />
        <SelectInput
          label="class"
          source="class"
          choices={[
            { id: TeaserClass.UNIVERSE, name: "Univers" },
            { id: TeaserClass.TEAM_MODEL, name: "Team Model" },
            { id: TeaserClass.FIDELITY_REWARD, name: "Vidéo privée" },
          ]}
          validate={validateRequired}
        />
        <SelectInput
          label="Status"
          source="publicationStatus"
          choices={[
            { id: PublicationStatus.DRAFT, name: "Brouillon" },
            { id: PublicationStatus.PUBLISHED, name: "Publié" },
          ]}
          validate={validateRequired}
        />
      </SimpleForm>
    </Edit>
  );
};
export const TeaserCreate = (props: CreateProps) => (
  <UniverseCreateUtil {...props} />
);
