import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { fetchUtils } from "react-admin";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Config from "../Config";
import CardUserStats from "./components/cardUserStats";
import AccordionSurvey from "./components/AccordionSurvey";

const apiUrl = Config.API_URL;
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const access_token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};

export type GraphDataType = { name: string; Nb: number };
export type RechartGraphType = {
  date: string;
  CA: number;
  pv: number;
  amt: number;
};
interface Survey {
  slug: string;
  // Ajoutez d'autres propriétés si nécessaire
}
const DashboardComponent = () => {
  // const dataProvider = useDataProvider();
  const [nbUsers, setNbUsers] = useState(0);
  const [nbMembers, setNbMembers] = useState(0);
  const [nbMembersPremium, setNbMembersPremium] = useState(0);

  const [surveys, setSurveys] = useState<Survey[]>([]);
  // const [preferedActressData, setPreferedActressData] = useState<
  //   GraphDataType[]
  // >([]);

  // const [winningOpinionData, setWinningOpinionData] = useState<GraphDataType[]>(
  //   []
  // );
  // const [rulesData, setRulesData] = useState<GraphDataType[]>([]);
  // const [duelOpinionData, setDuelOpinionData] = useState<GraphDataType[]>([]);
  // const [jokerOpinionData, setJokerOpinionData] = useState<GraphDataType[]>([]);
  // const [loveRoomOpinionData, setLoveRoomOpinionData] = useState<
  //   GraphDataType[]
  // >([]);
  // const [videoFormatData, setVideoFormatData] = useState<GraphDataType[]>([]);
  // const [anonimitytData, setAnonimityData] = useState<GraphDataType[]>([]);
  // const [nbTemptressData, setNbTemptressData] = useState<GraphDataType[]>([]);
  // const [favoriteMomentsData, setFavoriteMomentsData] = useState<
  //   GraphDataType[]
  // >([]);
  // const [realityShowOpinionData, setRealityShowOpinionData] = useState<
  //   GraphDataType[]
  // >([]);
  // const [nbEpisodesOpinionData, setNbEpisodesOpinionData] = useState<
  //   GraphDataType[]
  // >([]);
  const [retention_stats, setRetention_stats] = useState<RechartGraphType[]>(
    []
  );

  // const computeBarchartData = (
  //   resBody: { [x: string]: any },
  //   setter: (arg0: GraphDataType[]) => void
  // ) => {
  //   delete resBody["Non répondu"];

  //   let nbAnswers = 0;
  //   const data: GraphDataType[] = [];
  //   Object.keys(resBody).forEach((key) => {
  //     nbAnswers += resBody[key];
  //   });
  //   Object.keys(resBody).forEach((key) => {
  //     data.push({
  //       name:
  //         key.substring(0, 20) +
  //         (key.length > 20 ? "..." : "") +
  //         "(" +
  //         ((resBody[key] / nbAnswers) * 100).toFixed() +
  //         "%)",
  //       Nb: resBody[key],
  //     });
  //   });
  //   setter(data.sort((a, b) => b.Nb - a.Nb));
  // };
  useEffect(() => {
    let url = `${apiUrl}/dashboard/user_stats`;
    httpClient(url)
      .then((json) => {
        setNbUsers(JSON.parse(json.body).total);
        setNbMembers(JSON.parse(json.body).members);
        setNbMembersPremium(JSON.parse(json.body).membersPremium);
      })
      .catch((e) => {
        console.log("ERROR dashboard", e);
      });
    url = `${apiUrl}/dashboard/surveys`;
    httpClient(url)
      .then((json) => {
        setSurveys(JSON.parse(json.body));
      })
      .catch((e) => {
        console.log("ERROR dashboard", e);
      });

    url = `${apiUrl}/dashboard/retention_stats`;
    httpClient(url)
      .then((json) => {
        setRetention_stats(JSON.parse(json.body));
      })
      .catch((e) => {
        console.log("ERROR dashboard", e);
      });
  }, []);
  return (
    <>
      <Card>
        <CardHeader title="Bienvenue dans le Dashboard administration" />
        <CardContent>Retrouve les différentes stats ici...</CardContent>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <CardUserStats
            nbUsers={nbUsers}
            nbMembers={nbMembers}
            nbMembersPremium={nbMembersPremium}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        style={{ marginTop: "20px", padding: "10px" }}
      >
        <Grid item xs={12}>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <LineChart
            width={1000}
            height={300}
            data={retention_stats}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              label={{
                value: "abonnements",
                position: "insideBottom",
                offset: -5,
              }}
            />
            <YAxis
              label={{ value: "Nb", angle: 0, position: "left", offset: -5 }}
              // domain={[0, 4000]}
              // domain={["auto", "auto"]}
            />
            <Tooltip contentStyle={{ color: "black" }} />
            <Line
              type="monotone"
              dataKey="unsubscribedCount"
              name="désabonnés"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="newSubscriptionsCount"
              stroke="#82ca9d"
              name="nouveaux abonnés"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="initialClients"
              stroke="#ff7200"
              name="abonnés début de période"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="finalClients"
              stroke="cyan"
              name="Nb abonnés fin de période"
              activeDot={{ r: 8 }}
            />
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
            />
            {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
            <Line type="monotone" dataKey="amt" stroke="#ff7200" /> */}
          </LineChart>
          {/* </ResponsiveContainer> */}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={2}
        style={{ marginTop: "20px", marginBottom: "20px", padding: "10px" }}
      >
        <Grid item xs={12}>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <LineChart
            width={1000}
            height={300}
            data={retention_stats}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              label={{
                value: "Taux de retention",
                position: "insideBottom",
                offset: -5,
              }}
            />
            <YAxis
              label={{ value: "%", angle: 0, position: "left", offset: -5 }}
              // domain={[0, 4000]}
              // domain={["auto", "auto"]}
            />
            <Tooltip contentStyle={{ color: "black" }} />
            <Line
              type="monotone"
              dataKey="retentionRate"
              stroke="orange"
              name="taux de rétention"
              activeDot={{ r: 8 }}
            />

            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
            />
            {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
            <Line type="monotone" dataKey="amt" stroke="#ff7200" /> */}
          </LineChart>
          {/* </ResponsiveContainer> */}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {surveys.map((survey, index) => (
          <Grid item xs={12} key={index}>
            <AccordionSurvey slug={survey.slug} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DashboardComponent;
