export enum PageType {
  MEDIA_TAG = "media_tag",
  MEDIA_TYPE = "media_type",
  PAGE = "page",
  HOME_PAGE = "home_page",
  VERIFY_PAGE = "verify_page",
  SUBSCRIPITON_PAGE = "subscription_page",
  THANK_PAGE = "thank_page",
  SEASON_PAGE = "season_page",
  RENEW_PAGE = "renew_page",
}
