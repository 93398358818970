import { MutableRefObject, useEffect, useRef } from "react";
import { useRecordContext } from "react-admin";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "video.js/dist/video-js.css";

const PlayerComponent = (props: {
  options: VideoJsPlayerOptions;
  onReady: any;
  backgroundImage: string;
  srtUrl: string;
}): JSX.Element => {
  const videoRef = useRef(null);
  //   const playerRef = useRef(null);
  //   const videoRef: LegacyRef<HTMLVideoElement> | null = useRef(null);
  const playerRef: MutableRefObject<VideoJsPlayer | null> = useRef(null);
  const { options, onReady, srtUrl } = props;
  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, {
        ...options,
        html5: {
          vhs: {
            withCredentials: true,
            overrideNative: true,
          },
        },
      }, () => {
        // console.log('player is ready');
        onReady && onReady(player);
      }));
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;

      // console.log('#'.repeat(30));
      let oldTracks = player.remoteTextTracks();
      // console.log('PLAYER remoteTextTracks', oldTracks.length);
      let i = oldTracks.length;
      while (i--) {
        // console.log('oldTracks', i, oldTracks[i]);
        player.removeRemoteTextTrack(oldTracks[i] as any);
      }
      if (srtUrl) {
        // console.log('add Track', srtUrl)
        player.addRemoteTextTrack(
          {
            kind: 'subtitles',
            src: srtUrl,
            srclang: 'fr',
            label: 'Français',
            default: true,
          },
          true,
        );
      }
      oldTracks = player.remoteTextTracks();
      // console.log('PLAYER remoteTextTracks', oldTracks.length);
      // i = oldTracks.length;
      // while (i--) {
      //   console.log('oldTracks', i, oldTracks[i]);
      // }

      player.autoplay(options.autoplay || false);
      player.src(options.sources || "");
    }
  }, [options, videoRef, onReady, srtUrl]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-players>
      <video
        ref={videoRef}
        className="video-js vjs-big-play-centered vjs-matrix"
        style={{
          height: "20vh",
          maxWidth: "25vw",
          // maxHeight: '50vh',
        //   margin: "auto",
          padding: 0,
          backgroundImage: `url(${props.backgroundImage})`,
          backgroundRepeat: "round",
        }}
      />
    </div>
  );
};

const VideoField = (props: any) => {
  const record = useRecordContext(props);
  const playerRef = useRef(null);
  if (!record.video_url || record.video_url ==='default') {
    return (
      <></>
    )
  }
  // resetCookies()
  const videoJsOptions: VideoJsPlayerOptions = {
    // lookup the options in the docs for more options
    defaultVolume: 0.0,
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: record.video_url,
        type: "application/x-mpegURL",
      },
    ],
  };
  if (record.srt_url) {
    videoJsOptions.tracks = [{
      kind:"subtitles", src:record.srt_url, srclang:"fr", label:"Français", default:true
    }]
  }

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
    player.volume(0);
    // you can handle player events here
    player.on("waiting", () => {
      // console.log('player is waiting');
    });

    player.on("dispose", () => {
      // console.log('player will dispose');
    });
  }; // return <video src={`${record.video_url}`} controls width="320" height="240"></video>;
  return (
    <PlayerComponent
      options={videoJsOptions}
      onReady={handlePlayerReady}
      backgroundImage={record.banner}
      srtUrl={record.srt_url}
    />
  );
};

export default VideoField;
