import classnames from "classnames";
import { BooleanField, BooleanFieldProps, Record } from "react-admin";
import { BooleanFieldStyles } from "./MediaListStyle";

interface  ColoredBooleanFieldProps extends BooleanFieldProps {
    source: string,
    record?: Record ,
}
const ColoredBooleanField = (props: ColoredBooleanFieldProps) => {
    const classes = BooleanFieldStyles();
    // console.log("props.source", props.source.split('.'));
    // console.log("props.record", props.record);
    let checkBool;
    if (props.record) {
        if (props.record[props.source.split('.')[0]]) {
            checkBool = props.record[props.source.split('.')[0]][props.source.split('.')[1]];
        } else {
            checkBool = props.record[props.source.split('.')[1]];
        }    
    } else {
        checkBool = false;
    }
    // console.log("props.record[props.source]", props.record[props.source.split('.')[0]][props.source.split('.')[1]])
    return (
        <BooleanField
            className={classnames({
                [classes.true]: checkBool === true,
                [classes.false]: checkBool === false,
            })}
            {...props}
        />
    );
};

export default ColoredBooleanField;