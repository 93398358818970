import { Card, CardContent, CardHeader } from "@material-ui/core";
import { styles } from "./cardStyle";

type typeProps = {
  surveyNbAnswered: number;
  surveyNbNotAnswered: number;
  refuseSurvey: number;
};
const CardUserStats = ({
  surveyNbAnswered,
  surveyNbNotAnswered,
  refuseSurvey,
}: typeProps) => {
  return (
    <Card style={styles.card}>
      <CardHeader
        style={styles.cardHeader}
        title="Sondage"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent style={styles.cardContent}>
        Répondu: {surveyNbAnswered}
      </CardContent>
      <CardContent style={styles.cardContent}>
        Répondra plus tard: {surveyNbNotAnswered}
      </CardContent>
      <CardContent style={styles.cardContent}>
        Ne veux pas répondre: {refuseSurvey}
      </CardContent>
    </Card>
  );
};

export default CardUserStats;
