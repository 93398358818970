export enum UserMsgStatus {
  PENDING = "pending",
  REMINDED = "reminded",
  ANSWERED = "answered",
  ANSWER_READ = "answer_read",
}

export enum EmailWorkflow {
  TO_SEND = "to-send",
  SENDING = "sending",
  SENT = "sent",
  ERROR = "error",
}
