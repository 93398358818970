import * as React from "react";
import { Record } from "react-admin";

interface UniverseTitleProps {
  record?: Record; // make this an optional property
}
const UniverseTitle = ({ record }: UniverseTitleProps) => {
  return <span>Univers {record ? record.title : ""}</span>;
};

export default UniverseTitle;
