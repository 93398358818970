import RichTextInput from "ra-input-rich-text";
import {
  BooleanInput,
  Create,
  CreateProps,
  FileField,
  FileInput,
  FormTab,
  ImageField,
  ImageInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
} from "react-admin";
import { AccessRestriction, PublicationStatus } from "../Media.enum";
import MediaTitle from "../MediaTitle";
import { Grid } from "@material-ui/core";
import { rowStyles, videoMsgStyle } from "../MediaListStyle";

const validateRequired = [required()];

const MediaCreate = (props: CreateProps) => {
  const classes = rowStyles();
  const videoMsgClasse = videoMsgStyle();

  // const theResource = useResourceContext();
  // console.log(theResource);
  // const checkTag = async (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const res = await dataProvider.checkTag(e);
  //   console.log(props);
  //   return res;
  // };
  return (
    <Create title={<MediaTitle />} {...props}>
      <TabbedForm>
        <FormTab label="Summary">
          <TextInput
            label="Titre"
            source="media.title"
            validate={validateRequired}
          />
          <RichTextInput
            label="description"
            source="media.description"
            toolbar={[
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "link"],

              // [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ["clean"],
            ]}
            validate={validateRequired}
          />
          <SelectInput
            label="Acces restriction"
            source="media.accessRestriction"
            choices={[
              { id: AccessRestriction.PUBLIC, name: "Public" },
              { id: AccessRestriction.MEMBER, name: "Membre" },
              { id: AccessRestriction.MEMBER_PREMIUM, name: "Membre Premium" },
            ]}
            initialValue={AccessRestriction.PUBLIC}
            validate={validateRequired}
          />
          <BooleanInput
            label="is18Plus"
            source="media.is18Plus"
            initialValue={false}
            validate={validateRequired}
          />
          <SelectInput
            label="Status"
            source="media.publicationStatus"
            choices={[
              { id: PublicationStatus.DRAFT, name: "Brouillon" },
              { id: PublicationStatus.PUBLISHED, name: "Publié" },
            ]}
            initialValue={PublicationStatus.DRAFT}
            validate={validateRequired}
          />
          <TextInput
            label="slug"
            source="media.slug"
            validate={validateRequired}
          />
        </FormTab>
        <FormTab label="Banner">
          <Grid container spacing={2} className={classes.widthAuto}>
            <Grid item xs={6}>
              <ImageInput source="banner" label="Banner" accept="image/*">
                <ImageField source="src" title="current banner" />
              </ImageInput>
            </Grid>

            {/* <Grid item xs={6}>
              <ImageInput
                source="mobile_banner"
                label="Mobile Banner"
                accept="image/*"
              >
                <ImageField source="src" title="mobile_banner" />
              </ImageInput>
            </Grid> */}
            {/* <Grid item xs={6}>
              <ImageInput source="topkif" label="Top Kif" accept="image/*">
                <ImageField source="src" title="topkif" />
              </ImageInput>
            </Grid> */}
            {/* {props.resource === "Gallery" && ( */}
            <Grid item xs={6}>
              <ImageInput
                source="home_banner"
                label="Home Banner"
                accept="image/*"
              >
                <ImageField source="src" title="Home Banner" />
              </ImageInput>
            </Grid>
            {/* )} */}
            <Grid item xs={6}>
              <div className={videoMsgClasse.videoMsg}>
                Renseignez les informations concertant la video, puis
                enregistrer, vous pourrez ensuite télécharger votre vidéo
              </div>
              {/* <FileInput
                source="teaser_video"
                label="Teaser video"
                accept="video/*"
              >
                <FileField source="src" title="teaser video" />
              </FileInput> */}
            </Grid>
            <Grid item xs={6}>
              <FileInput
                source="teaser_srt"
                label="Tease subtitles"
                accept=".srt"
              >
                <FileField source="src" title="teaser subtitles" />
              </FileInput>
            </Grid>
          </Grid>
        </FormTab>
        {props.resource === "Video" && (
          <FormTab label="Video">
            <div className={videoMsgClasse.videoMsg}>
              Renseignez les informations concertant la video, puis enregistrer,
              vous pourrez ensuite télécharger votre vidéo
            </div>
            <FileInput
              source="srt_input"
              label="Video subtitle File"
              accept=".srt"
            >
              <FileField source="src" title="srt" />
            </FileInput>
          </FormTab>
        )}
        {props.resource === "Gallery" && (
          <FormTab label="Gallery">
            <ImageInput
              source="pictures_input"
              label="images"
              multiple={true}
              accept="image/*"
            >
              <ImageField source="src" title="image" />
            </ImageInput>
          </FormTab>
        )}
        <FormTab label="SEO">
          <TextInput
            label="Titre SEO"
            source="media.titleSeo"
            validate={validateRequired}
          />
          <TextInput
            label="description SEO"
            source="media.descriptionSeo"
            validate={validateRequired}
          />
          <TextInput label="keywords SEO" source="media.keywordsSeo" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default MediaCreate;
