import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles({
  title: {
    display: "block",
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },
  srtUrl: {
    display: "block",
    width: "280px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    color: "cyan",
    marginTop: "4rem",
  },
});
const videoMsgStyle = makeStyles({
  videoMsg: {
    width: "auto",
    fontWeight: "bolder",
    color: "cyan",
  },
});

const BooleanFieldStyles = makeStyles({
  true: {
    color: "green",
  },
  false: {
    color: "red",
  },
});

const mediaImageFieldStyles = makeStyles((theme: Theme) => ({
  image: {
    // This will override the style of the <img> inside the <div>
    width: 100,
    // height: 50,
  },
}));

const rowStyles = makeStyles({
  hidden: {
    display: "none",
  },
  imgGallery: {
    maxWidth: "80vw",
    overflow: "scroll",
  },
  richText: {
    width: "100%",
  },
  rowFlex: {
    "& section": {
      display: "flex",
    },
    "& li": {
      width: "50%",
    },
    "& ul": {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  widthAuto: {
    width: "auto",
  },
  displayFlex: {
    '& [class*="RaLabeled"]': {
      display: "flex",
    },
    '& [class*="RaImageInput-dropZone"]': {
      height: "fit-content",
    },
  },
  displayFlexColumn: {
    '& [class*="RaLabeled"]': {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    '& [class*="RaImageInput-dropZone"]': {
      height: "fit-content",
    },
  },
});

export {
  useStyles,
  mediaImageFieldStyles,
  rowStyles,
  BooleanFieldStyles,
  videoMsgStyle,
};
