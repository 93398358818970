import {
  ArrayInput,
  EditProps,
  FormDataConsumer,
  ImageField,
  ImageInput,
  Labeled,
  SimpleFormIterator,
  useRecordContext,
} from "react-admin";
import ConditionalImageField from "./ConditionalImageField";
import { rowStyles } from "./MediaListStyle";

const GalleryForm = (props: EditProps) => {
  const classes = rowStyles();
  const reorderValidation = (value: any, allValues: any) => {
    if (
      !value &&
      JSON.stringify(
        record.pictures_input.map((x: any) => {
          return x ? x.desc : null;
        })
      ) !==
        JSON.stringify(
          allValues.pictures_input.map((x: any) => {
            return x ? x.desc : null;
          })
        )
    ) {
      record.pictures_input = allValues.pictures_input.slice(
        0,
        record.pictures_input.length
      );
    }
    return undefined;
  };
  const validateReOrder = [reorderValidation];
  const record = useRecordContext(props);
  return (
    <>
      <div className={classes.imgGallery}>
        <ConditionalImageField
          source="pictures_input"
          src="url"
          title="desc"
          label="gallery image"
          // record={record}
        />
      </div>
      <ArrayInput source="pictures_input" className={classes.rowFlex}>
        <SimpleFormIterator
          getItemLabel={(index) => `${index}`}
          // removeButton={<CustomRemoveButton />}
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              if (scopedFormData && scopedFormData.url && getSource) {
                return (
                  <>
                    <Labeled label="Original image">
                      {/* <Labeled label={getSource("desc")}> */}
                      <ImageField source={getSource("url")} />
                    </Labeled>
                    {/* <ImageInput
                      source={getSource("new_picture")}
                      label="desc"
                      multiple={false}
                      accept="image/*"
                      // validate={validateReOrder}
                    >
                      <ImageField source={getSource("src")} title="image" />
                    </ImageInput> */}
                  </>
                );
              } else {
                return null;
              }
            }}
          </FormDataConsumer>
          <ImageInput
            source="new_picture"
            label="desc"
            multiple={false}
            accept="image/*"
            validate={validateReOrder}
          >
            <ImageField source="src" title="image" />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export default GalleryForm;
