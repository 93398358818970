import * as React from "react";
import { ImageField, ImageFieldProps, useRecordContext } from "react-admin";

interface ConditionalImageFieldProps extends ImageFieldProps {
  source: string;
}
const ConditionalImageField = (props: ConditionalImageFieldProps) => {
  // const record = props.record;
  const record = useRecordContext(props);

  if (record && Array.isArray(record[props.source])) {
    for (const aPic of record[props.source]) {
      if (aPic?.url === "default") {
        aPic.url = "/images/default.webp";
      }
    }
  } else if (record && record[props.source] === "default") {
    record[props.source] = "/images/default.webp";
  }

  return record && record[props.source] ? <ImageField {...props} /> : null;
};

export default ConditionalImageField;
