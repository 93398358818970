export enum AccessRestriction {
  PUBLIC = "public",
  MEMBER = "member",
  MEMBER_PREMIUM = "member_premium",
}
export enum PublicationStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum UniverseClass {
  EPISODE = "episode",
  TEAM_MODEL = "team-model",
  FIDELITY_REWARD = "fidelity-reward",
}
