import React, {useCallback} from 'react'
import { Button, ButtonProps, useRecordContext } from 'react-admin';
import {useForm} from 'react-final-form';

type myProps = ButtonProps & {
  source: string,
  target: string,
  //   resource: string;
    // id:string | undefined,
  };
const ClearFieldButton = ({ source, target, ...props}: myProps) => {
  const form = useForm();
  const record = useRecordContext(props);
  const handleClick = useCallback(() => {  
    form.change(target, null);   
  }, [form, target]);
  return record && record[source] && record[source] !== 'default' ?
    <Button onClick={handleClick} {...props} style={{color:"red"}}/>
    : <></>
  ;
};

export default ClearFieldButton;