export const styles = {
  card: {
    //   width: "25vw",
    margin: "1em",
    // background: "purple",
    background: "#eee",
    //   maxHeight: "80vh",
    //   overflow: "scroll",
    maxWidth: "88vw",
  },
  cardHeader: {
    padding: "10px",
    background: "purple",
  },
  cardContent: {
    fontSize: "0.8em",
    margin: "0px 0px 5px 10px",
    padding: "5px",
    background: "#eee",
    color: "black",
  },
};
