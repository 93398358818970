// https://codesandbox.io/s/github/marmelab/react-admin/tree/master/examples/simple?file=/src/posts/TagReferenceInput.tsx:2623-2629
import * as React from "react";
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  useCreate,
  useCreateSuggestionContext,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    margin: "0 24px",
    position: "relative",
  },
  input: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "50%",
  },
});

const TagReferenceInput = ({
  ...props
}: {
  reference: string;
  source: string;
  label?: string;
}) => {
  const classes = useStyles();

  // const { change } = useForm();
  // const [filter, setFilter] = useState(true);

  // const handleAddFilter = () => {
  //     setFilter(!filter);
  //     change('tags', []);
  // };
  return (
    <div className={classes.input}>
      <ReferenceArrayInput {...props}>
        <AutocompleteArrayInput
          create={<CreateTag {...props} />}
          optionText="label"
          // optionText="name.en"
        />
      </ReferenceArrayInput>
      {/* <Button
                name="change-filter"
                className={classes.button}
                onClick={handleAddFilter}
            >
                Filter {filter ? 'Unpublished' : 'Published'} Tags
            </Button> */}
    </div>
  );
};

const CreateTag = (props: any) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = React.useState(filter || "");
  const [create] = useCreate("Tag");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    create(
      {
        payload: {
          data: {
            label: value,
          },
        },
      },
      {
        onSuccess: (data: any) => {
          setValue("");
          const choice = data;
          props.record.media.tagIds.push(data.data.id);
          props.record.media.tags.push(data.data.id);
          // mutate({
          //     type: 'update',
          //     resource: 'comments',
          //     payload: {
          //         id: event.target.dataset.id,
          //         data: { isApproved: true, updatedAt: new Date() }
          //     },
          // });
          onCreate(choice);
        },
      }
    );
    return false;
  };
  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MuiTextField
            label="New tag"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TagReferenceInput;
