import { Card, CardContent, CardHeader } from "@material-ui/core";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GraphDataType } from "../Dashboard";
import { styles } from "./cardStyle";
import { useEffect, useState } from "react";

type CardProps = {
  statData: any;
};
interface ResBodyItem {
  label: string;
  count: number;
}

const computeBarchartData = (
  answers: ResBodyItem[],
  setter: (arg0: GraphDataType[]) => void
) => {
  // console.log("resBody", answers);

  let nbAnswers = 0;
  const data: GraphDataType[] = [];

  // Calculer le nombre total de réponses
  answers.forEach((item) => {
    nbAnswers += item.count;
  });

  // Créer le tableau de données formaté
  answers.forEach((item) => {
    data.push({
      name:
        item.label.substring(0, 20) +
        (item.label.length > 20 ? "..." : "") +
        "(" +
        ((item.count / nbAnswers) * 100).toFixed() +
        "%)",
      Nb: item.count,
    });
  });

  setter(data);
  setter(data.sort((a, b) => b.Nb - a.Nb));
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={20}
        // dy={16}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-30)"
      >
        {payload.value.split("(")[1].split(")")[0]}
      </text>
      <text
        x={0}
        y={5}
        // dy={16}
        textAnchor="middle"
        fill="#666"
        transform="rotate(-30)"
      >
        {payload.value.split("(")[0]}
      </text>
    </g>
  );
};

const CardSurveyBarchartNew = ({ statData }: CardProps) => {
  const [graphData, setGraphData] = useState<GraphDataType[]>([]);
  useEffect(() => {
    computeBarchartData(statData.answers, setGraphData);
  }, [statData]);

  return (
    <Card style={styles.card}>
      <CardHeader
        style={styles.cardHeader}
        title={statData.question}
        titleTypographyProps={{ variant: "h6" }}
      />
      {/* {Object.keys(preferedActress).map((key) => {
          const value = preferedActress[key];
          return (
            <CardContent key={key}>
              {key} {value}
            </CardContent>
          );
        })} */}
      <CardContent style={styles.cardContent}>
        <ResponsiveContainer width="95%" height={250}>
          <BarChart
            // width={600}
            // height={250}
            data={graphData}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 70,
            }}
            barSize={30}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis
              // label="actrices"
              dataKey="name"
              // scale="point"

              // angle={-20}
              padding={{ left: 10, right: 30 }}
              scale="point"
              tickMargin={30}
              tick={<CustomizedAxisTick />}
              allowDataOverflow={true}
              interval={0}
            />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" align="right" />
            <Bar
              dataKey="Nb"
              fill="#8884d8"
              // background={{ fill: "#eee" }}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
export default CardSurveyBarchartNew;
