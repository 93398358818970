import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  ListProps,
  NumberField,
  BulkDeleteButton,
  TextInput,
  SelectInput,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import { mediaImageFieldStyles, useStyles } from "../MediaListStyle";
import ColoredBooleanField from "../ColoredBooleanField";
import ConditionalImageField from "../ConditionalImageField";
import { AccessRestriction } from "../Media.enum";
import { ReactElement, JSXElementConstructor } from "react";

const descriptionPanel = ({ id, record, resource }: any) => (
  <div dangerouslySetInnerHTML={{ __html: record.media.description }} />
);

const mediaAccessRestrictions = [
  { id: AccessRestriction.PUBLIC, name: "Public" },
  { id: AccessRestriction.MEMBER, name: "Member" },
  { id: AccessRestriction.MEMBER_PREMIUM, name: "Member PREMIUM" },
];
const mediaFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    label="accessRestriction"
    source="media.accessRestriction"
    choices={mediaAccessRestrictions}
  />,
];

const MediaList = (props: ListProps) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  // const classes = useStyles();
  const imageFieldClasses = mediaImageFieldStyles();
  const titleFieldClass = useStyles();

  return (
    // <List {...props} filters={MediaFilters} >
    <List {...props} bulkActionButtons={false} filters={mediaFilters}>
      {isSmall ? (
        // <SimpleList className={classes.table}
        <SimpleList
          primaryText={(record) =>
            record.media ? record.media.title : record.title
          }
          secondaryText={(record) =>
            `Nb images ${record.pictures ? record.pictures.length : 0}`
          }
          tertiaryText={(record) =>
            `${
              record.media
                ? record.media.accessRestriction
                : record.accessRestriction
            }`
          }
          leftAvatar={(record) => (record.banner ? record.banner : null)}
        />
      ) : (
        // <Datagrid rowClick="edit" classes={classes} expand={descriptionPanel} >
        <Datagrid rowClick="edit" expand={descriptionPanel}>
          <ConditionalImageField
            source="banner"
            label="Banner"
            classes={imageFieldClasses}
          />
          {/* <TextField source="id" /> */}
          <TextField
            label="Titre"
            source="media.title"
            className={titleFieldClass.title}
          />
          <TextField
            label="access restriction"
            source="media.accessRestriction"
          />

          {/* <TextField label="Saison" source="seasonName" sortable={false} /> */}
          {/* {props.resource === "Video" && (
          )} */}
          {/* <TextField label="Classification" source="media.class" /> */}
          <TextField label="Status" source="media.publicationStatus" />
          {props.resource === "Video" && (
            <TextField label="Encoding Workflow" source="encodingWorkflow" />
          )}
          {/* <TextField label="Description" source="media.description" />
              <TextField label="Titre SEO" source="media.titleSeo" />
              <TextField label="Description SEO" source="media.descriptionSeo" /> */}
          {/* <TextField label="Keywords SEO" source="media.keywordsSeo" /> */}
          <TextField label="slug" source="media.slug" />
          <ColoredBooleanField label="is18Plus" source="media.is18Plus" />
          <NumberField label="Note" source="media.grade" />
          <DateField showTime={true} source="created_at" />
          <DateField showTime={true} source="updated_at" />
          {props.resource === "Gallery" && (
            <TextField label="Nb images" source="pictures.length" />
          )}
          <BulkDeleteButton />
          {/* <EditButton /> */}
        </Datagrid>
      )}
    </List>
  );
};

export default MediaList;
