import UniverseCreate from "./UniverseCreate";
import UniverseEdit from "./UniverseEdit";
import UniverseList from "./UniverseList";

const Universes = {
  UniverseListUtil: UniverseList,
  UniverseCreateUtil: UniverseCreate,
  UniverseEditUtil: UniverseEdit,
  // MediaShow: MediaShow,
  // BookIcon: BookIcon,
};

export default Universes;
