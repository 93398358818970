import * as React from "react";
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    DateField,
    ListProps,
   } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';


    
export const TransactionList = (props: ListProps) => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  
    return (
    <List {...props}  bulkActionButtons={false} >
        {isSmall ? (
            <SimpleList 
                primaryText={record => `Abonnement - ${record.title}`}
                secondaryText={record => `Tentative ${record.numberAttempt} du ${record.forPaiementAt}`}
                tertiaryText={record => `${record.amount}€`}
            />
        ) : (
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField label="Titre" source="title" />
            <TextField label="Prix" source="amount" />
            <TextField label="Nombre de tentatives" source="numberAttempt" />
            <TextField label="Paiement pour le" source="forPaiementAt" />
            <DateField showTime={true} source="createdAt" />
            <DateField showTime={true} source="updatedAt" />
        </Datagrid>
        )}
    </List>
    );
}

