import Config from "../../Config";
import { fetchUtils } from "react-admin";
import { AccessRestriction } from "./Media.enum";

const apiUrl = Config.API_URL;
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const access_token = localStorage.getItem("access_token");
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};
const MediaDataProvider = {
  // getSeason: async (mediaList: any[]) => {
  //   for (const element of mediaList) {
  //     for (const webzone of element.media.webzones) {
  //       if (webzone.type === WebzoneType.SEASON) {
  //         element.seasonName = webzone.name;
  //       }
  //     }
  //   }
  //   return mediaList;
  // },

  getMediasBanner: async (mediaList: any[]) => {
    for (const element of mediaList) {
      try {
        let url =
          apiUrl + "/media/" + element.media.id + "/banner_vignette/url";
        let { body } = await httpClient(url);
        element.banner = body;
        // url = apiUrl + '/media/' + element.media.id + '/mobile_banner/url';
        // ({ body } = await httpClient(url));
        // element.mobile_banner = body;
        // url = apiUrl + '/media/' + element.media.id + '/topkif/url';
        // ({ body } = await httpClient(url));
        // element.topkif = body;
        // url = apiUrl + '/media/' + element.media.id + '/home_banner/url';
        // ({ body } = await httpClient(url));
        // element.home_banner = body;
      } catch (error) {
        console.log("ERROR", error);
        element.banner = null;
      }
    }
    return mediaList;
  },

  getMediaContent: async (
    resource: string,
    json: {
      media: {
        id: string;
        /*tags: any;*/ tagIds: string[];
        /*webzones: any;*/ webzoneIds: string[];
        accessRestriction: AccessRestriction;
      };
      banner: string | null;
      mobile_banner: string | null;
      topkif: string | null;
      home_banner: string | null;
      pictures_input: { url: any; desc: any }[];
      video_url: string | null;
      srt_url: string | null;
      teaser_video: string | null;
      teaser_srt: string | null;
    }
  ) => {
    try {
      // GET BANNERS
      let url = apiUrl + "/media/" + json.media.id + "/banner_vignette/url";
      let { body } = await httpClient(url);
      json.banner = body === "default" ? null : body;
      url = apiUrl + "/media/" + json.media.id + "/mobile_banner_vignette/url";
      ({ body } = await httpClient(url));
      json.mobile_banner = body === "default" ? null : body;
      url = apiUrl + "/media/" + json.media.id + "/topkif_vignette/url";
      ({ body } = await httpClient(url));
      json.topkif = body === "default" ? null : body;
      url = apiUrl + "/media/" + json.media.id + "/home_banner_vignette/url";
      ({ body } = await httpClient(url));
      json.home_banner = body === "default" ? null : body;
      url = apiUrl + "/media/" + json.media.id + "/TeaserVideo";
      ({ body } = await httpClient(url));
      json.teaser_video = body === "default" ? null : body;
      url = apiUrl + "/media/" + json.media.id + "/TeaserSrt";
      ({ body } = await httpClient(url));
      json.teaser_srt = body === "" ? null : body;
      // console.log("json.teaser_video", json.teaser_video)
      if (resource === "Gallery") {
        // GET PICTURES
        url = apiUrl + "/picture/of_gallery/" + json.media.id;
        const gallery_pictures = await httpClient(url);
        json.pictures_input = [];
        for (const picture of gallery_pictures.json) {
          json.pictures_input.push({
            url: picture.url_vignette,
            desc: picture.id,
          });
        }
      } else if (resource === "Video") {
        //TODO: Mettre la bonne route
        url = `${apiUrl}/video/${json.media.id}/url`;
        ({ body } = await httpClient(url));
        json.video_url = body;
        url = `${apiUrl}/video/${json.media.id}/srturl`;
        ({ body } = await httpClient(url));
        json.srt_url = body;
      }
    } catch (error) {
      console.log("ERROR", error);
      json.banner = null;
      json.mobile_banner = null;
      json.topkif = null;
      json.home_banner = null;
      json.teaser_video = null;
      json.teaser_srt = null;
    }
    return json;
  },

  updateMedia: async (
    resource: string,
    params: {
      data: {
        media: {
          id: any;
          tagIds: string[];
          webzoneIds: any;
          accessRestriction: AccessRestriction;
        };
        video_input: { rawFile: string | Blob };
        srt_input: { rawFile: string | Blob };
        pictures: any[];
        pictures_input: any[];
        banner_update: { rawFile: string | Blob };
        mobile_banner_update: { rawFile: string | Blob };
        topkif_update: { rawFile: string | Blob };
        home_banner_update: { rawFile: string | Blob };
        teaser_video_update: { rawFile: string | Blob };
        teaser_srt_update: { rawFile: string | Blob };
      };
      previousData: {
        media: { accessRestriction: AccessRestriction };
      };
      id: any;
    }
  ) => {
    // UPDATE MEDIA
    // console.log("updateMedia", params);
    const url = `${apiUrl}/Media/${params.data.media.id}`;
    await httpClient(url, {
      method: "PATCH",
      body: JSON.stringify(params.data.media),
    });

    // MOVE S3 content if access restriction change
    if (
      resource === "Video" &&
      params.data.media.accessRestriction !==
        params.previousData.media.accessRestriction
    ) {
      // console.log(
      //   "Access restriction change to",
      //   params.data.media.accessRestriction
      // );
      const body = {
        isPrivate:
          params.data.media.accessRestriction ===
          AccessRestriction.MEMBER_PREMIUM,
      };
      const url = `${apiUrl}/${resource}/${params.data.media.id}/move`;
      await httpClient(url, {
        method: "PATCH",
        body: JSON.stringify(body),
      });
    }
    //UPDATE VIDEO
    if (resource === "Video" && params.data.video_input === null) {
      const url = `${apiUrl}/${resource}/${params.data.media.id}/deleteVideo`;
      console.log("DELETE", url);
      await httpClient(url, {
        method: "DELETE",
      });
      // } else if (resource === "Video" && params.data.video_input) {
      //   console.log("BEFORE UPLOAD VIDEO");
      //   const url = `${apiUrl}/${resource}/${params.data.media.id}/uploadVideo`;
      //   const formData = new FormData();
      //   formData.append("file", params.data.video_input.rawFile);
      //   const toto = await httpClient(url, {
      //     method: "POST",
      //     body: formData,
      //   });
      //   console.log("AFTER UPLOAD VIDEO", toto);
    }
    if (resource === "Video" && params.data.srt_input === null) {
      const url = `${apiUrl}/${resource}/${params.data.media.id}/deleteSrt`;
      console.log("DELETE", url);
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (resource === "Video" && params.data.srt_input) {
      const url = `${apiUrl}/${resource}/${params.data.media.id}/uploadSrt`;
      const formData = new FormData();
      formData.append("file", params.data.srt_input.rawFile);
      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    //UPDATE GALLERY
    if (resource === "Gallery") {
      const previous_pictures = params.data.pictures
        ? params.data.pictures.map((x: any) => x.id)
        : [];
      const current_pictures = params.data.pictures_input
        ? params.data.pictures_input
            .map((x: any) => x.desc)
            .filter((x: any) => x != null)
        : [];
      const to_create_pictures = params.data.pictures_input
        ? params.data.pictures_input /*.map((x: any) => x.new_picture)*/
            .filter((x: any) => x.new_picture != null)
        : [];
      // console.log(previous_pictures);
      // console.log(current_pictures);
      // console.log(to_create_pictures);
      for (const picture of previous_pictures) {
        if (
          !current_pictures.includes(picture) ||
          to_create_pictures
            .map((x: any) => x.desc)
            .filter((x: any) => x != null)
            .includes(picture)
        ) {
          // console.log("remove picture", picture);
          const url = `${apiUrl}/picture/${picture}`;
          await httpClient(url, {
            method: "DELETE",
          });
        }
      }
      const url = `${apiUrl}/${resource}/${params.data.media.id}/uploadPicture`;
      for (const picture of to_create_pictures) {
        const formData = new FormData();
        formData.append("file", picture.new_picture.rawFile);
        const result = await httpClient(url, {
          method: "POST",
          body: formData,
        });
        const idx = current_pictures.indexOf(picture.desc);
        if (idx > -1) {
          current_pictures[idx] = result.json.id;
        } else {
          current_pictures.push(result.json.id);
        }
        // console.log(result);
      }
      if (current_pictures.length > 0) {
        const urlReorder = `${apiUrl}/picture/reorder_gallery/${params.data.media.id}`;
        await httpClient(urlReorder, {
          method: "PATCH",
          body: JSON.stringify({ picture_ids: current_pictures }),
        });
      }
    }
    // UPDATE BANNERS
    if (params.data.banner_update === null) {
      const url = `${apiUrl}/Media/${params.data.media.id}/remove/banner`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.banner_update) {
      const url = `${apiUrl}/Media/${params.id}/upload/banner`;
      const formData = new FormData();
      formData.append("file", params.data.banner_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.mobile_banner_update === null) {
      const url = `${apiUrl}/Media/${params.data.media.id}/remove/mobile_banner`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.mobile_banner_update) {
      const url = `${apiUrl}/Media/${params.id}/upload/mobile_banner`;
      const formData = new FormData();
      formData.append("file", params.data.mobile_banner_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.topkif_update === null) {
      const url = `${apiUrl}/Media/${params.data.media.id}/remove/topkif`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.topkif_update) {
      const url = `${apiUrl}/Media/${params.id}/upload/topkif`;
      const formData = new FormData();
      formData.append("file", params.data.topkif_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.home_banner_update === null) {
      const url = `${apiUrl}/Media/${params.data.media.id}/remove/home_banner`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.home_banner_update) {
      const url = `${apiUrl}/Media/${params.id}/upload/home_banner`;
      const formData = new FormData();
      formData.append("file", params.data.home_banner_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.teaser_video_update === null) {
      const url = `${apiUrl}/Media/${params.data.media.id}/teaserVideo`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.teaser_video_update) {
      const url = `${apiUrl}/Media/${params.id}/TeaserVideo`;
      const formData = new FormData();
      formData.append("file", params.data.teaser_video_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.teaser_srt_update === null) {
      const url = `${apiUrl}/Media/${params.data.media.id}/teaserSrt`;
      await httpClient(url, {
        method: "DELETE",
      });
    } else if (params.data.teaser_srt_update) {
      const url = `${apiUrl}/Media/${params.id}/TeaserSrt`;
      const formData = new FormData();
      formData.append("file", params.data.teaser_srt_update.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
  },

  createMedia: async (
    resource: string,
    params: {
      data: {
        media: any;
        video_input: { rawFile: string | Blob };
        srt_input: { rawFile: string | Blob };
        pictures_input: any;
        banner: { rawFile: string | Blob };
        mobile_banner: { rawFile: string | Blob };
        topkif: { rawFile: string | Blob };
        home_banner: { rawFile: string | Blob };
        teaser_video: { rawFile: string | Blob };
        teaser_srt: { rawFile: string | Blob };
      };
    }
  ): Promise<any> => {
    // console.log("params.data", params.data)
    const { json } = await httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data.media),
    });

    // if (resource === "Video" && params.data.video_input) {
    //   const url = `${apiUrl}/${resource}/${json.id}/uploadVideo`;
    //   const formData = new FormData();
    //   formData.append("file", params.data.video_input.rawFile);
    //   await httpClient(url, {
    //     method: "POST",
    //     body: formData,
    //   });
    // }
    if (resource === "Video" && params.data.srt_input) {
      const url = `${apiUrl}/${resource}/${json.id}/uploadSrt`;
      const formData = new FormData();
      formData.append("file", params.data.srt_input.rawFile);
      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (resource === "Gallery" && params.data.pictures_input) {
      const url = `${apiUrl}/${resource}/${json.id}/uploadPicture`;
      for (const picture of params.data.pictures_input) {
        const formData = new FormData();
        formData.append("file", picture.rawFile);
        await httpClient(url, {
          method: "POST",
          body: formData,
        });
      }
    }
    if (params.data.banner) {
      const url = `${apiUrl}/Media/${json.id}/upload/banner`;
      const formData = new FormData();
      formData.append("file", params.data.banner.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.mobile_banner) {
      const url = `${apiUrl}/Media/${json.id}/upload/mobile_banner`;
      const formData = new FormData();
      formData.append("file", params.data.mobile_banner.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.topkif) {
      const url = `${apiUrl}/Media/${json.id}/upload/topkif`;
      const formData = new FormData();
      formData.append("file", params.data.topkif.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });
    }
    if (params.data.home_banner) {
      const url = `${apiUrl}/Media/${json.id}/upload/home_banner`;
      const formData = new FormData();
      formData.append("file", params.data.home_banner.rawFile);

      await httpClient(url, {
        method: "POST",
        body: formData,
      });

      // if (params.data.teaser_video) {
      //   const url = `${apiUrl}/Media/${json.id}/TeaserVideo`;
      //   const formData = new FormData();
      //   formData.append("file", params.data.teaser_video.rawFile);

      //   await httpClient(url, {
      //     method: "POST",
      //     body: formData,
      //   });
      // }
      if (params.data.teaser_srt) {
        const url = `${apiUrl}/Media/${json.id}/TeaserSrt`;
        const formData = new FormData();
        formData.append("file", params.data.teaser_srt.rawFile);

        await httpClient(url, {
          method: "POST",
          body: formData,
        });
      }
    }

    return json;
  },
};

export default MediaDataProvider;
