export enum PublicationStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum ImagePosition {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
  NONE = "none",
}
