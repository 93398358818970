import { Card, CardContent, CardHeader } from "@material-ui/core";
import { styles } from "./cardStyle";

type typeProps = {
  nbUsers: number;
  nbMembers: number;
  nbMembersPremium: number;
};

const cardUserStats = ({ nbUsers, nbMembers, nbMembersPremium }: typeProps) => {
  return (
    <Card style={styles.card}>
      <CardHeader
        style={styles.cardHeader}
        title="Utilisateurs"
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent style={styles.cardContent}>
        Nombre Total: {nbUsers}
      </CardContent>
      <CardContent style={styles.cardContent}>Membres: {nbMembers}</CardContent>
      <CardContent style={styles.cardContent}>
        Membres Premiums: {nbMembersPremium}
      </CardContent>
    </Card>
  );
};

export default cardUserStats;
