import RichTextInput from "ra-input-rich-text";
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  TextInput,
  required,
  EditProps,
  Edit,
  SelectInput,
  SimpleForm,
  DateField,
  Toolbar,
  SaveButton,
  DateInput,
} from "react-admin";
import { WithWidthProps } from "@material-ui/core";
import { SurveyStatus } from "./Utils/Surveys/surveys.enum";
import { useFormState } from "react-final-form";

const validateRequired = [required()];
const statusChoice = [
  { id: SurveyStatus.DRAFT, name: "Brouillon" },
  { id: SurveyStatus.ACTIVE, name: "Active" },
  { id: SurveyStatus.FINISHED, name: "Finie" },
];
const SurveysFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    label="Status"
    source="publicationStatus"
    choices={statusChoice}
  />,
  // <SelectInput label="Type" source="type" choices={pageTypes} />,
];

export const SurveyList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={SurveysFilters}
      sort={{ field: "order", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="slug" />
        <TextField label="Status" source="surveyStatus" />
        <TextField label="order" source="order" />
        <DateField source="subscriptionEndAfter" />

        {/* <BulkDeleteButton /> */}
      </Datagrid>
    </List>
  );
};

// export const SurveysCreate = (props: CreateProps) => {
//   return (
//     <Create {...props}>
//       <SimpleForm>
//         <TextInput label="Titre" source="title" validate={validateRequired} />
//         <RichTextInput
//           label="description"
//           source="content"
//           toolbar={[
//             ["bold", "italic", "underline", "strike"], // toggled buttons
//             ["blockquote", "link"],

//             // [{ header: 1 }, { header: 2 }], // custom button values
//             [{ list: "ordered" }, { list: "bullet" }],
//             [{ script: "sub" }, { script: "super" }], // superscript/subscript
//             [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
//             [{ direction: "rtl" }], // text direction

//             [{ size: ["small", false, "large", "huge"] }], // custom dropdown
//             // [{ header: [1, 2, 3, 4, 5, 6, false] }],

//             [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//             [{ font: [] }],
//             [{ align: [] }],

//             ["clean"],
//           ]}
//           validate={validateRequired}
//         />
//         <SelectInput
//           label="Status"
//           source="publicationStatus"
//           choices={[
//             { id: PublicationStatus.DRAFT, name: "Brouillon" },
//             { id: PublicationStatus.PUBLISHED, name: "Publié" },
//           ]}
//           initialValue={PublicationStatus.DRAFT}
//           validate={validateRequired}
//         />
//       </SimpleForm>
//     </Create>
//   );
// };
const CustomToolbar = (props: WithWidthProps) => {
  const { dirty } = useFormState();
  console.log("dirty", dirty);
  return (
    <Toolbar {...props}>
      <SaveButton disabled={!dirty} />
    </Toolbar>
  );
};

export const SurveyEdit = (props: EditProps) => {
  return (
    <Edit mutationMode="pessimistic" {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput label="Titre" source="slug" validate={validateRequired} />
        <TextInput label="Titre" source="title" validate={validateRequired} />
        <SelectInput
          label="Status"
          source="surveyStatus"
          choices={[
            { id: SurveyStatus.DRAFT, name: "Brouillon" },
            { id: SurveyStatus.ACTIVE, name: "active" },
            { id: SurveyStatus.FINISHED, name: "finished" },
          ]}
          validate={validateRequired}
        />
        <DateInput source="subscriptionEndAfter" />
        <RichTextInput
          label="description"
          source="description"
          toolbar={[
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "link"],

            // [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
          ]}
          validate={validateRequired}
        />
      </SimpleForm>
    </Edit>
  );
};
