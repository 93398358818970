import RichTextInput from "ra-input-rich-text";
import {
  List,
  Datagrid,
  TextField,
  ListProps,
  CreateProps,
  Create,
  TextInput,
  required,
  EditProps,
  Edit,
  SelectInput,
  SimpleForm,
  BulkDeleteButton,
} from "react-admin";
import { PageType } from "./Utils/Page/Page.enum";
import { useStyles } from "./PageListStyle";

const validateRequired = [required()];
const pageTypes = [
  { id: PageType.HOME_PAGE, name: "Home Page" },
  { id: PageType.PAGE, name: "Page" },
  { id: PageType.MEDIA_TYPE, name: "Media type" },
  { id: PageType.MEDIA_TAG, name: "Media Tag" },
  { id: PageType.VERIFY_PAGE, name: "Vérification" },
  { id: PageType.SUBSCRIPITON_PAGE, name: "Subscription" },
  { id: PageType.THANK_PAGE, name: "Subscription Thank" },
  { id: PageType.SEASON_PAGE, name: "Saison" },
  { id: PageType.RENEW_PAGE, name: "Renouvellement" },
];
const PageFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput label="Type" source="type" choices={pageTypes} />,
];
export const PageList = (props: ListProps) => {
  const styleClasses = useStyles();
  return (
    <List {...props} bulkActionButtons={false} filters={PageFilters}>
      <Datagrid rowClick="edit">
        <TextField source="title" />
        <TextField source="titleSeo" />
        <TextField source="content" className={styleClasses.content} />
        <TextField source="descriptionSeo" />
        <TextField source="keywordsSeo" />
        <TextField source="slug" />
        <TextField source="type" />
        <BulkDeleteButton />
      </Datagrid>
    </List>
  );
};

export const PageCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Titre" source="title" validate={validateRequired} />
        <TextInput
          label="Titre SEO"
          source="titleSeo"
          // validate={validateRequired}
        />
        <RichTextInput
          label="description"
          source="content"
          toolbar={[
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block", "link", "image"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"],
          ]}
          validate={validateRequired}
        />
        <TextInput
          label="description SEO"
          source="descriptionSeo"
          // validate={validateRequired}
        />
        <TextInput
          label="keywords SEO"
          source="keywordsSeo"
          // validate={validateRequired}
        />
        <TextInput label="slug" source="slug" validate={validateRequired} />
        <SelectInput
          label="Type de page"
          source="type"
          choices={[
            { id: PageType.HOME_PAGE, name: "Home Page" },
            { id: PageType.PAGE, name: "Page" },
            { id: PageType.MEDIA_TYPE, name: "Media type" },
            { id: PageType.MEDIA_TAG, name: "Media Tag" },
            { id: PageType.VERIFY_PAGE, name: "Vérification" },
            { id: PageType.SUBSCRIPITON_PAGE, name: "Subscription" },
            { id: PageType.THANK_PAGE, name: "Subscription Thank" },
            { id: PageType.SEASON_PAGE, name: "Saison" },
            { id: PageType.RENEW_PAGE, name: "Renouvellement" },
          ]}
          initialValue={PageType.PAGE}
          validate={validateRequired}
        />
      </SimpleForm>
    </Create>
  );
};

export const PageEdit = (props: EditProps) => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput label="Titre" source="title" validate={validateRequired} />
      <TextInput
        label="Titre SEO"
        source="titleSeo"
        // validate={validateRequired}
      />
      <RichTextInput
        label="description"
        source="content"
        toolbar={[
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block", "link", "image"],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction

          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
        ]}
        validate={validateRequired}
      />
      <TextInput
        label="description SEO"
        source="descriptionSeo"
        // validate={validateRequired}
      />
      <TextInput
        label="keywords SEO"
        source="keywordsSeo"
        // validate={validateRequired}
      />
      <TextInput label="slug" source="slug" validate={validateRequired} />
      <SelectInput
        label="Type de page"
        source="type"
        choices={[
          { id: PageType.HOME_PAGE, name: "Home Page" },
          { id: PageType.PAGE, name: "Page" },
          { id: PageType.MEDIA_TYPE, name: "Media" },
          { id: PageType.MEDIA_TAG, name: "Tag" },
          { id: PageType.VERIFY_PAGE, name: "Vérification" },
          { id: PageType.SUBSCRIPITON_PAGE, name: "Subscription" },
          { id: PageType.THANK_PAGE, name: "Subscription Thank" },
          { id: PageType.SEASON_PAGE, name: "Saison" },
          { id: PageType.RENEW_PAGE, name: "Renouvellement" },
        ]}
        validate={validateRequired}
      />
    </SimpleForm>
  </Edit>
);
