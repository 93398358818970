import { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { ButtonProps, useRecordContext } from "react-admin";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import frLocale from "date-fns/locale/fr";

const localeMap = {
  fr: frLocale,
};

type getdateProps = ButtonProps & {
  show: boolean;
  handleConfirm: (event: any, date: Date) => void;
  handleClose: (event: any) => void;
};
const GetDateModal = ({ ...props }: getdateProps) => {
  const record = useRecordContext(props);
  const [showModal, setShowModal] = useState(props.show);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date) setSelectedDate(date);
  };

  const handleClose = useCallback(
    (event) => {
      setShowModal(false);
      props.handleClose(event);
    },
    [props]
  );
  const handleConfirm = useCallback(
    (event) => {
      setShowModal(false);
      props.handleConfirm(event, selectedDate);
    },
    [props, selectedDate]
  );

  useEffect(() => {
    setShowModal(props.show);
  }, [props.show]);

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      onClick={useCallback((event) => {
        event.stopPropagation();
      }, [])}
      disableEnforceFocus
    >
      <DialogTitle>Désinscription forcée</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "red" }}>
          <strong>
            Etes vous sure de vouloir forcer le désabonnement de {record.email}
          </strong>
        </DialogContentText>
        <DialogContentText>
          Indiquez la date à partir de laquelle les paiements ont été contestés
        </DialogContentText>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["fr"]}>
          <KeyboardDatePicker
            clearable
            variant="static"
            value={selectedDate}
            placeholder="05/10/2018"
            onChange={(date) => handleDateChange(date)}
            // minDate={new Date()}
            format="dd/MM/yyyy"
          />
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={handleConfirm}>Désinscrire</Button>
      </DialogActions>
    </Dialog>
  );
};
export default GetDateModal;
